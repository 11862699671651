html,
body,
#root,
#root>div {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.bo-user {
  overflow: hidden;
}

body.po-user {
  overflow: auto;
}

/* Apply scaling and adjust width/height for all users */
body.scaled {
  transform: scale(0.9);
  transform-origin: 0 0;
  width: 111.11%;
  /* Compensate for scaling (1 / 0.9 = 1.1111) */
  height: 111.11%;
  /* Compensate for scaling */
}

/*Use MediaQuery to enhance the scaling of the website*/
/* Firefox specific adjustments to compensate for scaling issues */
@media screen and (-moz-images-in-menus: 0) {
  body.scaled {
    width: calc(100% / 0.9);
    height: calc(100% / 0.9);
  }
}

@media only screen and (max-width: 600px) {
  .mobile-margin {
    margin-left: 0px !important;
  }
  .profile_icon {
    width: 40px;
    height:40px;
  }
}

iframe {
  display: none;
}

body.no-scroll {
  overflow: hidden;
}